import React,{Component} from 'react';
import  {connect} from 'react-redux'
import authAction from '../../store/Actions/authAction';
import classes from './SignIn.css'
import Loader from '../../components/Loader/Loader';
class SignIn extends Component{
    state={
        email:"",
        password:"",
        loading:false
    }
    onFormValueHandler=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    onFormSubmit=(e)=>{
        e.preventDefault();
        this.setState({
            loading:true
        })
        this.props.login(this.state.email,this.state.password,()=>{
            this.setState({
                loading:false
            })
            this.props.history.push('/')
        },()=>{
            this.setState({
                loading:false
            })  
        })
    }
    render(){
        // return(
        //     <div className="signin-form" >
        //     <form onSubmit={this.onFormSubmit}>
        //     SignIn
        //         {<h3>{this.props.msg}</h3>}
        //         <input type="email" name="email" onChange={this.onFormValueHandler} value={this.state.email}/>
        //         <input type="password" name="password" onChange={this.onFormValueHandler} value={this.state.password}/>
        //         <input type="submit"/>
        //     </form>
        //     </div>
        // );
        return(
            <div className={classes.signinForm}>
  <div className={classes.session}>
    <div class={classes.left}>
    {this.state.loading?<Loader/>:null}
        
    </div>
    <form onSubmit={this.onFormSubmit} class={classes.logIn} autocomplete="off"> 
      <h4>We are <span>MMR</span></h4>
      <p>Welcome back! Log in to your account to create resumes:</p>
      <p style={{color:"red"}}>{<h3>{this.props.msg}</h3>}</p>
      <div className={classes.floatinglabel}>
        <input placeholder="Email"  id="email" type="email" name="email" onChange={this.onFormValueHandler} value={this.state.email} autocomplete="off"/>
        <label for="email">Email:</label>
        <div className={classes.icon}>
        <i className="fa fa-envelope-o"></i>
        </div>
      </div>
      <div className={classes.floatinglabel}>
        <input placeholder="Password" type="password" name="password" onChange={this.onFormValueHandler} value={this.state.password} id="password" autocomplete="off"/>
        <label for="password">Password:</label>
        <div className={classes.icon}>
          
        <i className="fa fa-key"></i>
          
        </div>
        
      </div>
      <button type="submit" onClick="return false;">Log in</button>
    </form>
  </div>
</div>
        )
    }
}
const mapStateToProps=state=>({
    loading: state.authReducer.loading,
    error:state.authReducer.error,
    msg:state.authReducer.msg,
    user:state.authReducer.user
})
const mapDispatchToProps=dispatch=>({
    login: (email,password,cb,ee)=>dispatch(authAction.login(email,password,cb,ee))
})
export default connect(mapStateToProps,mapDispatchToProps)(SignIn);