import axios from 'axios';
import {serverURL} from './config/config'
const axi = (token)=>{
    const instance = axios.create({
        baseURL:serverURL,
    })
    
    instance.defaults.headers.common['Authorization'] = `JWT ${token}`;
    return instance;
}

export default axi;