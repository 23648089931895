import React, { Component } from 'react';
import classes from './App.css';
import { Route, Switch, withRouter } from 'react-router-dom'
import SignIn from './containers/SignIn/Sigin';
import SignUp from './containers/SignUp/SignUp';
import Navbar from './components/Navbar/Nav';
import { connect } from 'react-redux'
import Welcome from './containers/welcome/Welcome';
import authAction from './store/Actions/authAction';
import Template from './containers/Templates/Template';
import Aux from './hoc/Aux';

class App extends Component {
  componentDidMount() {
    // if (!this.props.user) {
    //   this.props.history.push('/signin')
    // }
  }
  render() {
    return (
      <div className={classes.App}>
          <Navbar user={this.props.user} logout={this.props.logout} history={this.props.history} />
        <Switch>
          {this.props.user ?
            <Aux>
              <Route path='/:username/:templateId/:edit?' component={Template} />
              <Route path='/' exact component={Welcome}/>
            </Aux>
            :
            <Aux>
              <Route path='/:username/:templateId/' component={Template} />
              <Route path='/signup' exact component={SignUp}/>
              <Route path='/signin' exact component={SignIn}/>
            </Aux>}

        </Switch>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.authReducer.user,
})
const mapDispatchToProps = dispatch => ({
  logout: () => { dispatch(authAction.logout()) }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
