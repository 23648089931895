import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as Constants from '../../config/Constants'
import authAction from '../../store/Actions/authAction';
import AllResumes from '../../components/AllResumes/AllResumes';
import Loader from '../../components/Loader/Loader';
import classes from './Welcome.css'
class Welcome extends Component {
    state = {
        data: null,
        loading: true
    }
    async componentDidMount() {
        try {
            let res = await this.props.axios.post(`/api/user/allTemplates`, {
                username: this.props.username
            })
            this.setState({
                data: res.data.templates,
                loading: false
            })
            console.log(res.data)
        } catch (err) {
            console.log(err)
            if (err.response.status === 401)
                this.props.logout()
            this.setState({
                loading: false
            })
        }

    }
    createANewTemplate = async (e, templateno) => {
        try {
            let res = await this.props.axios.post('/api/user/create', {
                templateno
            })
            this.props.history.push(`/${this.props.username}/${res.data.templateId}/edit`)

        } catch (err) {
            console.log(err)
            if (err.response.status === 401)
                this.props.logout()
        }
        // console.log(res.data);

    }
    deleteAResume = async (id) => {
        this.setState({
            loading: true
        })
        try {
            let res = await this.props.axios.post(`/api/user/deleteTemplate`, {
                username: this.props.username,
                templateId: id
            })
            this.setState({
                data: res.data.templates,
                loading: false
            })
            console.log(res.data)
        } catch (err) {
            console.log(err)
            if (err.response.status === 401)
                this.props.logout()
            this.setState({
                loading: false
            })
        }
    }
    edit = (id) => {
        this.props.history.push(`/${this.props.username}/${id}/edit`);
    }
    render() {
        if (!this.state.loading)
            return (
                <div className={classes.welcomeContainer}>
                    {/* {this.state.data} */}
                    <div>
                        <button className="btn" onClick={(e) => this.createANewTemplate(e, Constants.Template1)}>Template1 New</button>
                        <button className="btn color-2" onClick={(e) => this.createANewTemplate(e, Constants.Template2)}>Template2 New</button>
                        <button className="btn color-2" onClick={(e) => this.createANewTemplate(e, Constants.Template3)}>Template3 New</button>
                        <button className="btn color-2" onClick={(e) => this.createANewTemplate(e, Constants.Template4)}>Template4 New</button>
                    </div>
                    <AllResumes edit={this.edit} data={this.state.data} deleteAResume={this.deleteAResume} />
                </div>
            );
        else
            return (<Loader msg="Getting all your resumes" />)
    }
}
const mapStateToProps = state => ({
    token: state.authReducer.token,
    username: state.authReducer.user,
    axios: state.authReducer.axios
})
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(authAction.logout()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);