import Template1 from '../../components/Templates/Template1/Template1'
import Template2 from '../../components/Templates/Template2/Template2'
import Template3 from '../../components/Templates/Template3/Template3'
import Template4 from '../../components/Templates/Template4/Template4'
import * as Constants from '../../config/Constants'
const TemplateSelector = (id)=>{
    if(id===Constants.Template1){
        return Template1
    }
    if(id===Constants.Template2){
        return Template2
    }
    if(id===Constants.Template3){
        return Template3
    }
    if(id===Constants.Template4){
        return Template4
    }
}
export default TemplateSelector;