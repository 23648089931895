import React from 'react';
import classes from './Nav.css'
import { Link } from 'react-router-dom'
import Aux from '../../hoc/Aux';
const Nav = (props) => {
    return (
        <nav role="navigation">
            <div id={classes.menuToggle}>

                <input type="checkbox" />


                <span></span>
                <span></span>
                <span></span>


                <ul id={classes.menu}>
                    <Link to="/"><li>Home</li></Link>
                    {props.user ? <Aux>
                        <a onClick={props.logout}><li>Logout</li></a>
                        <a ><li>{props.user}</li></a>
                    </Aux> :<Aux><a onClick={()=>{props.history.push('/signin')}} ><li>Login</li></a>
            <a onClick={()=>{props.history.push('/signup')}} ><li>SignUp</li></a></Aux>}
                </ul>
            </div>
        </nav>
    )
};
export default Nav;