import React, { Component } from 'react';
import classes from '../Template.css'
import InputToTemplate from '../../../components/InputToTemplate/InputToTemplate';
import Aux from '../../../hoc/Aux';
import { connect } from 'react-redux'
import authAction from '../../../store/Actions/authAction';
import { withRouter } from 'react-router-dom'
import Loader from '../../../components/Loader/Loader';
class Template1 extends Component {
    state = {
        loading:false
    }
    print = () => {
            window.print()
            
    }
    componentDidMount() {
        this.setState({
            ...this.state,
            ...this.props.state
        })
    }

    goToNextSection = (i) => {
        let key = Object.keys(this.state.input);
        let index = key.indexOf(this.state.q);
        this.setState({
            q: key[index + i]
        })
    }
    inputHandler = (e, q, i = null, j = 0) => {
        let newInput = { ...this.state.input };
        console.log(i, j);

        if (!Array.isArray(newInput[q])) {
            if (!Array.isArray(newInput[q][e.target.name])) {
                newInput[q] = {
                    ...newInput[q],
                    [e.target.name]: {
                        ...newInput[q][e.target.name],
                        value: e.target.value
                    }
                }


            } else {
                newInput[q][e.target.name][j].value = e.target.value
                // console.log(newInput[q][e.target.name[j]])
                // newInput[q] = {
                //     ...newInput[q],
                //     [e.target.name[j]]: [
                //         {
                //             value: e.target.value
                //         }
                //     ]
                // }
            }
        }
        else {
            if (!Array.isArray(newInput[q][i][e.target.name]))
                newInput[q][i] = {
                    ...newInput[q][i],
                    [e.target.name]: {
                        ...newInput[q][i][e.target.name],
                        value: e.target.value
                    }
                }
            else {
                newInput[q][i][e.target.name][j].value = e.target.value;
                // console.log(target);

                // newInput[q][i] = {
                //     ...newInput[q][i],
                //     [e.target.name[j]]: {
                //         ...newInput[q][i][e.target.name[j]],
                //         value: e.target.value
                //     }
                // }
            }
        }
        this.setState({
            input: newInput
        })
    }
    addSection = (q) => {

        let newInput = JSON.parse(JSON.stringify({ ...this.state.input }));;
        newInput[q].push(JSON.parse(JSON.stringify(newInput[q][0])));

        this.setState({
            input: newInput
        })
    }
    deleteSection = (q, i) => {

        let newInput = { ...this.state.input };
        if (newInput[q].length > 1)
            newInput[q].splice(i, 1);


        this.setState({
            input: newInput
        })
    }
    addSubSection = (name, q, i = null) => {
        let newInput = JSON.parse(JSON.stringify({ ...this.state.input }));
        if (i !== null)
            newInput[q][i][name].push({
                label: newInput[q][i][name][0].label,
                value: ""
            })
        else {
            console.log(q, name);

            newInput[q][name].push({
                label: newInput[q][name][0].label,
                value: ""
            })
        }
        this.setState({
            input: newInput
        })
    }
    deleteSubSection = (name, q, i = null, j) => {
        let newInput = JSON.parse(JSON.stringify({ ...this.state.input }));

        if (i !== null) {
            if (newInput[q][i][name].length > 1)
                newInput[q][i][name].splice(j, 1);
        }
        else {
            console.log(q, name);
            if (newInput[q][name].length > 1)
                newInput[q][name].splice(j, 1);
        }
        this.setState({
            input: newInput
        })
    }
    submitForm = async (e) => {
        e.preventDefault();
        this.setState({
            loading:true
        })
        try {
            await this.props.axios.post('/api/user/update', {
                username: this.props.params.username,
                templateId: this.props.params.templateId,
                data: this.state
            })
            this.setState({
                loading:false
            },()=>{

                alert("Saved")
            })
        } catch (err) {
            console.log(err)
            if (err.response.status === 401)
                this.props.logout()
            this.props.history.push('/signin')
        }


    }
    render() {
        let Component = this.props.component;
        // console.log("TempStruct",this.props.state);

        if (this.state.q) {
            if (this.props.edit) {
                let buttons = null;
                if (this.state.q === Object.keys(this.state.input).slice(-1)[0]) {
                    buttons = (<div><br /><button className="btn color-2" onClick={() => this.goToNextSection(-1)}>Back</button><button className="btn color-3" onClick={this.submitForm}>Finish</button></div>)
                }
                else if (this.state.q === Object.keys(this.state.input).slice(0)[0]) {
                    buttons = (<div><br /><button className="btn" onClick={() => this.goToNextSection(1)}>Next</button></div>)
                }
                else {
                    buttons = (<div><br /><button className="btn color-2" onClick={() => this.goToNextSection(-1)}>Back</button><button className="btn color-1" onClick={() => this.goToNextSection(1)}>Next</button></div>)

                }
                console.log(this.state.input[this.state.q], Array.isArray(this.state.input[this.state.q]))
                let Input = null;
                if (!Array.isArray(this.state.input[this.state.q]))
                    Input = Object.keys(this.state.input[this.state.q]).map((d, i) => {
                        // console.log(d,this.state.input[this.state.q][d].label);
                        if (i === 0) return null;
                        console.log(d);
                        if (!Array.isArray(this.state.input[this.state.q][d]))
                            return (<InputToTemplate key={i} label={this.state.input[this.state.q][d].label} name={d} value={this.state.input[this.state.q][d].value} inputHandler={(e) => this.inputHandler(e, this.state.q)} />)
                        else {
                            return (<Aux>
                                {this.state.input[this.state.q][d].map((dex, i) => {
                                    console.log(d);


                                    return (<Aux><InputToTemplate key={i} label={dex.label} name={d} value={dex.value} inputHandler={(e) => this.inputHandler(e, this.state.q, null, i)} /><button className="btn color-4" onClick={() => this.deleteSubSection(d, this.state.q, null, i)}>Delete Field</button></Aux>)
                                })}
                                <button className="btn" onClick={() => this.addSubSection(d, this.state.q, null)}>Add Field</button>
                            </Aux>

                            )
                        }
                    })
                else {
                    Input = this.state.input[this.state.q].map((de, id) => {
                        let a = Object.keys(de).map((d, i) => {
                            console.log(d);
                            if (i === 0) return null;
                            if (!Array.isArray(de[d]))
                                return (<InputToTemplate key={i} label={de[d].label} name={d} value={de[d].value} inputHandler={(e) => this.inputHandler(e, this.state.q, id)} />)
                            else {
                                return (<Aux>
                                    {de[d].map((dex, i) => {
                                        console.log(dex);
                                        return (<Aux><InputToTemplate key={i} label={dex.label} name={d} value={dex.value} inputHandler={(e) => this.inputHandler(e, this.state.q, id, i)} /><button className="btn color-4" onClick={() => this.deleteSubSection(d, this.state.q, id, i)}>Delete Field</button></Aux>)

                                    })}
                                    <button className="btn" onClick={() => this.addSubSection(d, this.state.q, id)}>Add Field</button>
                                </Aux>
                                )
                            }
                        })
                        return (<div className="section"><h1>{de.label}</h1>{a}<button className="btn color-5" onClick={() => this.deleteSection(this.state.q, id)}>Delete Section</button></div>)

                        // console.log(d);

                    })
                    Input = (<Aux>{Input}<button className="btn" onClick={() => this.addSection(this.state.q)}>Add Section</button></Aux>)
                }
                return (
                    <div className={classes.templateEdit}>
                    {this.state.loading?<Loader msg="Saving"/>:null}
                        <div className={classes.leftHalf}>
                           <div className={classes.sticky}>{buttons}</div> 
                            <h1>{this.state.input[this.state.q].label}</h1>
                            <div className={classes.sectionList}>{Input}</div>
                        </div>
                        <div className={classes.rightHalf}>
                            <Component {...this.state.input} />
                        </div>
                    </div>
                );
            }
            else
                return (
                        <div id={classes.templateView}>
                            <Component {...this.state.input} />
                            <button className={"btn "+classes.printBtn} onClick={this.print}>Print</button>
                        </div>

                );
        } else {
            return <h1>Loading</h1>
        }
    }
}
const mapStateToProps = state => ({
    axios: state.authReducer.axios
})
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(authAction.logout()),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Template1));