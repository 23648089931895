import React from 'react';
import classes from './Style.css'
import Aux from '../../../hoc/Aux';
const Template3 = (props) => {
    let parser = new DOMParser();
    return (
        <div className={classes.template3Body}>

            <article className={classes.lWrapper}>

                <div className={classes.lHeader}>
                    <div className={classes.lHeaderCol}>
                        <h1 className={classes.Title}>
                            <span className={classes.uShadow}>
                                {props.personalInfo.first_name.value}<br />
                                {props.personalInfo.last_name.value}
                            </span>
                            <span className={classes.TitleSub}>{props.personalInfo.profession.value}</span>
                        </h1>
                    </div>
                    <div className={classes.lHeaderCol+" "+classes.Contact}>
                        <div>{props.address.city.value}, {props.address.state.value}, {props.address.country.value}</div>
                        <div><a className={classes.uLink} href={props.personalInfo.site.value} target="_blank">{props.personalInfo.site.value}</a></div>
                        <div><a className={classes.uLink} href={`mailto:${props.personalInfo.email.value}`} target="_blank">{props.personalInfo.email.value}</a></div>
                    </div>
                </div>

                <section className={classes.lSection}>
                    <h2 className={classes.lSectionTitle+" "+classes.h3+classes.uHidden}>Profile</h2>
                    <div className={classes.lSectionContent}>
                        <p>{props.profile.prof.value}</p>
                    </div>
                </section>

                <section className={classes.lSection}>
                    <h2 className={classes.lSectionTitle+" "+classes.h3}>Skills</h2>
                    <div className={classes.lSectionContent}>
                        <ul>
                            {props.skills.skill.map((d, i) => {
                                return (

                                    <li key={i}>{d.value}</li>
                                )
                            })}

                        </ul>
                    </div>
                </section>

                <section className={classes.lSection}>
                    <h2 className={classes.lSectionTitle+" "+classes.h3}>Experience</h2>
                    <div className={classes.lSectionContent}>
                        {props.experiences.map((d, i) => {
                            console.log("Experience",d.start.value)
                            return (<Aux key={i}>
                                <h3>{d.jobtitle.value}</h3>
                                {d.name.value}, {d.location.value}<br />
                                {d.start.value} - {d.end.value}

                                <ul>    {d.points.map((a, j) => {

                                    return (<li key={j}>{a.value}</li>)
                                })}
                                </ul></Aux>)
                        })}


                    </div>
                </section>

                <section className={classes.lSection}>
                    <h2 className={classes.lSectionTitle+" "+classes.h3}>Education</h2>
                    <div className={classes.lSectionContent}>
                    {props.education.map((d,i)=>{
                        return(<Aux key={i}>
                            <h3>{d.courseName.value}</h3>
                            {d.college.value} ({d.location.value})<br /> {d.start.value} - {d.end.value}
        <p>{d.summary.value}</p>

                        </Aux>)
                    })}
                    </div>
                </section>

                <section className={classes.lSection}>
                    <h2 className={classes.lSectionTitle+" "+classes.h3}>Interests</h2>
                    <div className={classes.lSectionContent}>
                        <p>{props.interests.interest.value}</p>
                    </div>
                </section>
            </article>

            <div className={classes.lWrapper+" "+classes.uKeyline}>
                <div className={classes.lFooter}>
                    <div className={classes.lFooterCol}>
                        <strong className={classes.Tag}>{props.personalInfo.first_name.value.slice(0,1)}. {props.personalInfo.last_name.value}</strong>
                    </div>
                    <div className={classes.lFooterCol+" "+classes.Contact}>
                        <a href={props.personalInfo.site.value} target="_blank">{props.personalInfo.site.value}</a>
                        <a href={`mailto:${props.personalInfo.email.value}`} target="_blank">{props.personalInfo.email.value}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Template3;