import React from 'react';
import classes from './Resume.css'
const Resume = (props)=>{
    return(
        <div className={classes.resumeList}>
            
            <div className={classes.wrapper}>
	<div className={classes.profile}>
		<h3 className={classes.name}>{props.id}</h3>
		<p className={classes.title}>{props.templateno}</p>
		<p className={classes.description}>Your previous template</p>
		
	</div>
	
	<div className={classes.socialIcons}>
		<div className={classes.icon}>
			<button onClick={props.edit}><i className="fa fa-pencil" aria-hidden="true"></i></button>
		
		</div>
		
		<div className={classes.icon}>
			<button onClick={props.deleteAResume}><i className="fa fa-trash" aria-hidden="true"></i></button>
			
		</div>
		
		{/* <div className="icon">
			<a href=""><i className="fab fa-twitter"></i></a>
			
		</div> */}
	</div>
</div>


        </div>
    )
}
export default Resume;