import React from 'react';
import Resume from './Resume/Resume';
import classes from './AllResumes.css'
const AllResumes = (props)=>{
    // let data = props.data.map(d=>{
    //     return(<Resume templateno={d.templateno}/>)
    // })
    let data = Object.keys(props.data).map(d=>{
        return{
            id:d,
            ...props.data[d]
        }
    })

    data = data.map((d,i)=>{
        return <Resume {...d} deleteAResume={()=>props.deleteAResume(d.id)} edit={()=>props.edit(d.id)} key={i}/>
    })
    console.log(props,data)
    return(
        <div className={classes.allResumes}>
               {data}
        </div>
    )
}
export default AllResumes;