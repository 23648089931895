import React, { Component } from 'react';
import { connect } from 'react-redux'
import authAction from '../../store/Actions/authAction';
import classes from './SignUp.css'
import Loader from '../../components/Loader/Loader';
class SignUp extends Component {
  state = {
    name: "",
    username: "",
    email: "",
    password: "",
    loading: false
  }
  onFormValueHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    })
    this.props.register(this.state.name, this.state.username, this.state.email, this.state.password, () => {
      this.setState({
        loading: false
      })
      this.props.history.push('/signin')
    })
  }
  render() {
    console.log(this.props);

    // return (
    //     <div className="signup-form">
    //         <form onSubmit={this.onFormSubmit}>
    //         SignUp
    //             {this.props.error ? <h3>{this.props.msg}</h3> : null}

    //             <input type="text" placeholder="Name" name="name" onChange={this.onFormValueHandler} value={this.state.name} />
    //             <input type="text" placeholder="Username" name="username" onChange={this.onFormValueHandler} value={this.state.username} />
    //             <input type="email" placeholder="Email" name="email" onChange={this.onFormValueHandler} value={this.state.email} />
    //             <input type="password" placeholder="Password" name="password" onChange={this.onFormValueHandler} value={this.state.password} />
    //             <input type="submit" />
    //         </form>
    //     </div>
    // );
    return (
      <div className={classes.signupForm}>
        <div className={classes.session}>
          <div className={classes.left}>

          </div>
          <form onSubmit={this.onFormSubmit}  autocomplete="off">
            <h4>We are <span>MMR</span></h4>
            <p>Welcome back! Signup to create your account to create resumes:</p>
            <p style={{ color: "red" }}>{<h3>{this.props.msg}</h3>}</p>
            <div className={classes.floatingLabel}>
              <input placeholder="Name" id="email" type="text" name="name" onChange={this.onFormValueHandler} value={this.state.name} autocomplete="off" />
              <label for="name">Name:</label>
              <div className={classes.icon}>
                <i className="fa fa-pencil"></i>
              </div>
            </div>
            <div className={classes.floatingLabel}>
              <input placeholder="Username" id="email" type="text" name="username" onChange={this.onFormValueHandler} value={this.state.username} autocomplete="off" />
              <label for="name">Username:</label>
              <div className={classes.icon}>
                <i className="fa fa-user-o"></i>
              </div>
            </div>
            <div className={classes.floatingLabel}>
              <input placeholder="Email" id="email" type="email" name="email" onChange={this.onFormValueHandler} value={this.state.email} autocomplete="off" />
              <label for="email">Email:</label>
              <div className={classes.icon}>
                <i className="fa fa-envelope-o"></i>
              </div>
            </div>
            <div className={classes.floatingLabel}>
              <input placeholder="Password" type="password" name="password" onChange={this.onFormValueHandler} value={this.state.password} id="password" autocomplete="off" />
              <label for="password">Password:</label>
              <div className={classes.icon}>

                <i className="fa fa-key"></i>

              </div>

            </div>
            {this.state.loading?<Loader/>:null}
            <button type="submit" onClick="return false;">Sign Up</button>
          </form>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  loading: state.authReducer.loading,
  error: state.authReducer.error,
  msg: state.authReducer.msg
})
const mapDispatchToProps = dispatch => ({
  register: (name, username, email, password, cb) => dispatch(authAction.register({ name, username, email, password }, cb))
})
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);