import React from 'react';
import classes from './Style.css'
const Template2 = (props) => {
    console.log(props.personalInfo.first_name.value);

    return (
        <div className={classes.template2Body}>
            <div className={classes.template2}>
                <header id={classes.info}>
                    <h1>{props.personalInfo.first_name.value} {props.personalInfo.last_name.value}</h1>
                    <span>{props.address.streetno.value}, {props.address.city.value}, {props.address.state.value}, {props.address.country.value} &bull;
             </span>
                </header>
                <section id={classes.statement}>
                    <h2>Statement of Purpose</h2>
                    <p>{props.statementOfPurpose.purpose.value}</p>
                </section>
                <section id={classes.employment}>
                    <h2>Employment</h2>
                    {props.employment.map((d, i) => {
                        return (
                            <section>
                                <div className={classes.jobtable}>
                                    <div className={classes.tablerow}>
                                        <span className={classes.jobtitle}>{d.jobtitle.value}</span>
                                        <span className={classes.right}>{d.start.value} - {d.end.value}</span>
                                    </div>
                                    <div className={classes.tablerow}>
                                        <span>{d.name.value}</span>
                                        <span className={classes.right}>{d.location.value}</span>
                                    </div>
                                </div>
                                <ul>
                                    {d.points.map(p => {
                                        return (<li>{p.value}</li>);
                                    })}


                                </ul>
                            </section>
                        )
                    })}

                </section>
                <section id={classes.skills}>
                    <h2>Experience</h2>
                    <div className={classes.skillstable}>
                        <div className={classes.tablerow}>
                            <ul className={classes.cell}>
                                {props.experiences.experience.map(d => {
                                    return (<li>{d.value}</li>)
                                })}
                            </ul>

                        </div>
                    </div>
                </section>
                <div className={classes.pagebreak}></div>
                <section id={classes.education}>
                    <h2>Education</h2>
                    {props.education.map((d, i) => {
                        return (
                            <div className={classes.edtable}>
                                <div className={classes.tablerow}>
                                    <span className={classes.jobtitle}>{d.course.value}</span>
                                    <span className={classes.right}>{d.date.value}</span>
                                </div>
                                <div className={classes.tablerow}>
                                    <span>{d.college.value}</span>
                                    <span className={classes.right}>{d.location.value}</span>
                                </div>
                            </div>

                        )
                    })}

                </section>
                <section id={classes.online}>
                    <h2>Online</h2>
                    <div className={classes.table}>
                        {props.online.map((d, i) => {
                            return (
                                <div className={classes.tablerow}>
                                    <span className={classes.onlinecell}>{d.linkTo.value}:</span>
                                    <span className={classes.urlcell}>{d.link.value}</span>
                                </div>

                            )
                        })}

                    </div>
                </section>

            </div>
        </div>
    )
}
export default Template2;