import React, { Component } from 'react';
import TemplateStruct from './TemplateStruct/TemplateStruct';
import AxiosNo from '../../axios';
import { serverURL } from '../../config/config';
import TemplateSelector from './TemplateSelector';
import Loader from '../../components/Loader/Loader';
import { connect } from 'react-redux'
import authAction from '../../store/Actions/authAction';

class Template extends Component {
    state = {
        selected: null,
        loading: true,
        template1: {
            q: 'personalInfo',
            input: {
                personalInfo: {
                    label: "Personal Info",
                    first_name: {
                        label: "First Name",
                        value: ""
                    },
                    last_name: {
                        label: "Last Name",
                        value: ""
                    }

                },
                next: {
                    label: "Next",
                    rollno: {
                        label: "Roll No",
                        value: ""
                    }
                },
                finish: {
                    label: "Finish",
                    rollno: {
                        label: "Course",
                        value: ""
                    }
                }

            }
        },
        template2: {
            q: 'personalInfo',
            input: {
                personalInfo: {
                    label: "Personal Info",
                    first_name: {
                        label: "First Name",
                        value: ""
                    },
                    last_name: {
                        label: "Last Name",
                        value: ""
                    }

                },
                address: {
                    label: "Address",
                    streetno: {
                        label: "Street No",
                        value: ""
                    },
                    city: {
                        label: "City",
                        value: ""
                    },
                    state: {
                        label: "State",
                        value: ""
                    },
                    country: {
                        label: "Country",
                        value: ""
                    },
                },
                statementOfPurpose: {
                    label: "Statement Of Purpose",
                    purpose: {
                        label: "Statement Of Purpose",
                        value: ""
                    }
                },
                employment: [
                    {
                        label: "Company",
                        jobtitle: {
                            label: "Job Title",
                            value: ""
                        },
                        name: {
                            label: "Company Name",
                            value: ""
                        },
                        start: {
                            label: "Start Date",
                            value: ""
                        },
                        end: {
                            label: "End Date",
                            value: ""
                        },
                        location: {
                            label: "Location",
                            value: ""
                        },
                        points: [
                            {
                                label: "Point",
                                value: ""
                            }
                        ],

                    }
                ],
                experiences: {
                    label: "Experience",
                    experience: [
                        {
                            label: "Exp",
                            value: ""
                        },
                        {
                            label: "Exp",
                            value: ""
                        }
                    ]
                },
                education: [
                    {
                        label: "Education",
                        course: {
                            label: "Course",
                            value: ""
                        },
                        college:
                        {
                            label: "College/School",
                            value: ""
                        }
                        ,
                        date: {
                            label: "Date",
                            value: ""
                        },
                        location: {
                            label: "Location",
                            value: ""
                        },
                    }
                ],
                online: [
                    {
                        label:"Online Links",
                        linkTo:{
                            label:"Link to",
                            value:""
                        },
                        link:{
                            label:"Link",
                            value:""
                        }
                    }
                ]


            },
            
        },
        template3: {
            q: 'personalInfo',
            input: {
                personalInfo: {
                    label: "Personal Info",
                    first_name: {
                        label: "First Name",
                        value: ""
                    },
                    last_name: {
                        label: "Last Name",
                        value: ""
                    },
                    profession:{
                        label: "Profession",
                        value: ""
                    },
                    site:{
                        label: "Your Website Link",
                        value: ""
                    },
                    email:{
                        label: "Your Email ",
                        value: ""
                    }

                },
                address: {
                    label: "Address",
                    
                    city: {
                        label: "City",
                        value: ""
                    },
                    state: {
                        label: "State",
                        value: ""
                    },
                    country: {
                        label: "Country",
                        value: ""
                    },
                },
                profile: {
                    label: "Profile",
                    prof: {
                        label: "Profile",
                        value: ""
                    }
                },
                skills: {
                    label:"Skills",
                    skill:[
                        {
                            label:"Skill",
                            value:""
                        }
                    ]
                },
                experiences: [
                    {
                        label: "Experience",
                        jobtitle: {
                            label: "Job Title",
                            value: ""
                        },
                        name: {
                            label: "Company Name",
                            value: ""
                        },
                        location: {
                            label: "Location",
                            value: ""
                        },
                        start: {
                            label: "Start Date",
                            value: ""
                        },
                        end: {
                            label: "End Date",
                            value: ""
                        },
                        
                        points: [
                            {
                                label: "Point",
                                value: ""
                            }
                        ],

                    }
                ],
                education: [
                    {
                        label: "Education",
                        courseName: {
                            label: "Course Name",
                            value: ""
                        },
                        college: {
                            label: "College/School",
                            value: ""
                        },
                        location: {
                            label: "Location",
                            value: ""
                        },
                        start: {
                            label: "Start Date",
                            value: ""
                        },
                        end: {
                            label: "End Date",
                            value: ""
                        },
                        
                        summary: 
                            {
                                label: "Summary",
                                value: ""
                            }
                        

                    }
                ],
                interests:{
                    label:"Interests",
                    interest:{
                        label:"Interests",
                        value:""
                    }
                } 
            }
        },
        template4: {
            q: 'personalInfo',
            input: {
                personalInfo: {
                    label: "Personal Info",
                    name: {
                        label: "Name",
                        value: ""
                    },
                    designation: {
                        label: "Designation",
                        value: ""
                    },
                    department: {
                        label: "Department",
                        value: ""
                    },
                    qualification: {
                        label: "Qualification",
                        value: ""
                    },
                    phone: {
                        label: "Phone Number",
                        value: ""
                    },
                    email: {
                        label: "Email Id",
                        value: ""
                    },
                    dob: {
                        label: "Date of birth",
                        value: ""
                    },
                    doj: {
                        label: "Date of Joining",
                        value: ""
                    },
                    image: {
                        label: "Image Link",
                        value: ""
                    },

                },
                specialization: {
                    label: "Specialization",
                    special: {
                        label: "Specialization",
                        value: ""
                    }
                },
                otherLinks: {
                    label: "Other Profile Links",
                    research: {
                        label: "Research Gate Link",
                        value: ""
                    },
                    teaching: {
                        label: "Personal Teaching Link",
                        value: ""
                    },
                    google: {
                        label: "Google Scholar Link",
                        value: ""
                    }
                },
                education: [
                    {
                        label: "Education Qualification",
                        degree: {
                            label: "Name of the Degree",
                            value: ""
                        },
                        year: {
                            label: "Year of Passing",
                            value: ""
                        },
                        college: {
                            label: "College/School",
                            value: ""
                        },
                    }
                ],
                teaching: [
                    {
                        label: "Teaching Experience",
                        programme: {
                            label: "Programme Name",
                            value: ""
                        },
                        dept: {
                            label: "Department And Organization",
                            value: ""
                        },
                        from: {
                            label: "From",
                            value: ""
                        },
                        to: {
                            label: "To",
                            value: ""
                        },
                        total: {
                            label: "Total Experience",
                            value: ""
                        },
                    }
                ],
                administrative: [
                    {
                        label: "Administrative Experience",
                        position: {
                            label: "Position Held",
                            value: ""
                        },
                        dept: {
                            label: "Department And Organization",
                            value: ""
                        },
                        from: {
                            label: "From",
                            value: ""
                        },
                        to: {
                            label: "To",
                            value: ""
                        },
                    }
                ],
                research: {
                    label: "Research Experience",
                    research: {
                        label: "Research Interests",
                        value: ""
                    },
                    statement: {
                        label: "Brief Research Statement",
                        value: ""
                    },
                   
                },
                respro: [
                    {
                        label: "Research Projects",
                        role: {
                            label: "Role",
                            value: ""
                        },
                        projectType: {
                            label: "Project Type",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        funding: {
                            label: "Funding Agency",
                            value: ""
                        },
                        from: {
                            label: "From",
                            value: ""
                        },
                        to: {
                            label: "To",
                            value: ""
                        },
                        amount: {
                            label: "Amount",
                            value: ""
                        },
                        status: {
                            label: "Status",
                            value: ""
                        },
                        
                    }
                ],
                journal: [
                    {
                        label: "Journal Publications",
                        year: {
                            label: "Year",
                            value: ""
                        },
                        name: {
                            label: "Journal Name",
                            value: ""
                        },
                        title: {
                            label: "Title & Vol. No.",
                            value: ""
                        },
                        index: {
                            label: "Indexing (SCI/Web of science/Scopus)",
                            value: ""
                        },
                    }
                ],
                conference: [
                    {
                        label: "Conference Publications",
                        year: {
                            label: "Year",
                            value: ""
                        },
                        conference: {
                            label: "Conference",
                            value: ""
                        },
                        author: {
                            label: "Author(s)",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        index: {
                            label: "Indexing ",
                            value: ""
                        },
                    }
                ],
                books: [
                    {
                        label: "Book/Chapters Written",
                        type: {
                            label: "Type (Book/Chapter)",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        publisher: {
                            label: "Publisher",
                            value: ""
                        },
                        author: {
                            label: "Author(s)",
                            value: ""
                        },
                        isbn: {
                            label: "ISBN ",
                            value: ""
                        },
                        year: {
                            label: "Year ",
                            value: ""
                        },
                    }
                ],
                ressup: [
                    {
                        label: "Research Supersion",
                        programme: {
                            label: "Programme Name",
                            value: ""
                        },
                        schName: {
                            label: "Scholar Name",
                            value: ""
                        },
                        topic: {
                            label: "Research Topic",
                            value: ""
                        },
                        status: {
                            label: "Status",
                            value: ""
                        },
                        year: {
                            label: "Year ",
                            value: ""
                        },
                        cosupervisor: {
                            label: "Co-Supervisor ",
                            value: ""
                        },
                    }
                ],
                patents: [
                    {
                        label: "Patents",
                        name: {
                            label: "Name",
                            value: ""
                        },
                        reg: {
                            label: "Reg./Ref.No.",
                            value: ""
                        },
                        doa: {
                            label: "Date of Award/Filling",
                            value: ""
                        },
                        awardingagency: {
                            label: "Awarding Agencies",
                            value: ""
                        },
                        status: {
                            label: "Status ",
                            value: ""
                        },
                    }
                ],
                confOrganized: [
                    {
                        label: "Conferences/Workshop/Courses Organized",
                        category: {
                            label: "Category",
                            value: ""
                        },
                        type: {
                            label: "Type",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        venue: {
                            label: "Venue",
                            value: ""
                        },
                        from: {
                            label: "From",
                            value: ""
                        },
                        to: {
                            label: "To",
                            value: ""
                        },
                        designation: {
                            label: "Designation ",
                            value: ""
                        },
                    }
                ],
                expertTalks: [
                    {
                        label: "Conferences/Workshop/Courses Organized",
                        title: {
                            label: "Title",
                            value: ""
                        },
                        place: {
                            label: "Place",
                            value: ""
                        },
                        year: {
                            label: "Year",
                            value: ""
                        },
                        doe: {
                            label: "Description of Event",
                            value: ""
                        },
                    }
                ],
                consultancy: [
                    {
                        label: "Consultancy",
                        title: {
                            label: "Title",
                            value: ""
                        },
                        client: {
                            label: "Client Organization",
                            value: ""
                        },
                        faculty: {
                            label: "Faculty Involved",
                            value: ""
                        },
                        amount: {
                            label: "Amount(INR)",
                            value: ""
                        },
                        status: {
                            label: "Status",
                            value: ""
                        },
                    }
                ],
                exposure: [
                    {
                        label: "International and National Exposure",
                        srno: {
                            label: "Sr. No.",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        description: {
                            label: "Description",
                            value: ""
                        },
                    }
                ],
                honors: [
                    {
                        label: "Honors & Recognitions Achieved",
                        srno: {
                            label: "Sr. No.",
                            value: ""
                        },
                        title: {
                            label: "Title",
                            value: ""
                        },
                        activity: {
                            label: "Activity",
                            value: ""
                        },
                        given: {
                            label: "Given By",
                            value: ""
                        },
                        year: {
                            label: "Year",
                            value: ""
                        },
                    }
                ],
            }
        }
    }
    async componentDidMount() {
        console.log();
        try {

            let res = await AxiosNo.post(`${serverURL}/api/user/get`, {
                username: this.props.match.params.username,
                templateId: this.props.match.params.templateId
            });
            console.log(res.data.templateData);
            if (res.data.templateData.q) {
                console.log("setstate", res.data.templateNo, res.data.templateData);

                this.setState({
                    [res.data.templateNo]: res.data.templateData,
                    selected: res.data.templateNo,
                    loading: false
                })
            } else {
                this.setState({
                    selected: res.data.templateNo,
                })
            }
        } catch (err) {
            console.log("Error", err)
            // if (err.response.status === 401)
            //     this.props.logout()
            // this.props.history.push('/signin')
        }

    }
    render() {
        console.log(this.state);

        if (this.state.selected) {
            if (this.props.match.params.edit === 'edit')
                return (
                    <TemplateStruct edit state={this.state[this.state.selected]} params={this.props.match.params} component={TemplateSelector(this.state.selected)} />
                );
            else
                return (
                    <TemplateStruct state={this.state[this.state.selected]} component={TemplateSelector(this.state.selected)} />
                );
        } else {
            return (<Loader msg="Getting previous data" />)
        }
    }
}
const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(authAction.logout()),
})
export default connect(null, mapDispatchToProps)(Template);