import React from 'react';
import classes from './Style.css'
import Aux from '../../../hoc/Aux';
const Template3 = (props) => {
    let parser = new DOMParser();
    return (
        <div className={classes.template4Body}>
            <div className={classes.template4BodyCont}>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-2">
                        <img className="resumepic" src={props.personalInfo.image.value} alt="Logo" width="122" height="122" />
                    </div>
                    <div className="col-sm-8">
                        <table className="profilemain resumetable" cellspacing="0" cellpadding="6" border="0">
                            <tbody><tr>
                                <td width="100px"><b>Name</b></td>
                                <td width="30px">:</td>
                                <td><b>{props.personalInfo.name.value}                  </b></td>
                            </tr>
                                <tr>
                                    <td><b>Designation</b></td>
                                    <td>:</td>
                                    <td>{props.personalInfo.designation.value}</td>
                                </tr>
                                <tr>
                                    <td><b>Department</b></td>
                                    <td>:</td>
                                    <td> {props.personalInfo.department.value}</td>
                                </tr>
                                <tr>
                                    <td valign="top"><b>Qualification</b></td>
                                    <td valign="top">:</td>
                                    <td valign="top">
                                        {props.personalInfo.qualification.value}                                                      </td>
                                </tr>

                                <tr>
                                    <td><b>Phone</b></td>
                                    <td>:</td>
                                    <td>{props.personalInfo.phone.value}</td>
                                </tr>
                                <tr>
                                    <td><b>Email ID</b></td>
                                    <td>:</td>
                                    <td>{props.personalInfo.email.value}</td>
                                </tr>

                                <tr>
                                    <td ><b>Date of Birth</b></td>
                                    <td>:</td>
                                    <td>{props.personalInfo.dob.value}
                                    </td>
                                </tr>
                                <tr>
                                    <td ><b>Date of Joining</b></td>
                                    <td>:</td>
                                    <td> {props.personalInfo.doj.value}

                                    </td>
                                </tr>

                            </tbody>
                        </table>


                    </div>
                    <div className="col-sm-1"></div>
                </div><div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 mrgn">
                        <h2>Specialization</h2>
                        <div className="sep2">
                            {props.specialization.special.value} </div>

                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 mrgn">
                        <h2>Other Profile Links</h2>
                        <div className="sep2"></div>

                        <table cellspacing="0" cellpadding="7" border="0">
                            <tbody>
                                <tr>
                                    <td >Research Gate Link</td>
                                    <td >:  </td>
                                    <td>{props.otherLinks.research.value}
                                    </td>
                                </tr>
                                <tr>
                                    <td >Personal Teaching Link</td>
                                    <td >: </td>
                                    <td>{props.otherLinks.teaching.value}
                                    </td>
                                </tr>
                                <tr>
                                    <td >Google Scholar Link</td>
                                    <td >: </td>
                                    <td>{props.otherLinks.google.value}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Education Qualification</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Name of the Degree</th>
                                    <th>Year of Passing</th>
                                    <th>Institute/University</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.education.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.degree.value}</td>
                                            <td>{d.year.value}</td>
                                            <td>{d.college.value} </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>


                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Teaching Experience</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Programme Name</th>
                                    <th>Department And Organization</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Total Experience</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.teaching.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.programme.value} </td>
                                            <td>{d.dept.value}</td>
                                            <td>{d.from.value}</td>
                                            <td>{d.to.value}</td>
                                            <td>{d.total.value}</td>
                                        </tr>

                                    )
                                })}
                            </tbody>
                        </table>


                    </div>
                    <div className="col-sm-1"></div>
                </div><div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Administrative Experience</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Position Held</th>
                                    <th>Department And Organization</th>
                                    <th>From</th>
                                    <th>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.administrative.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.position.value} </td>
                                            <td>{d.dept.value}</td>
                                            <td>{d.from.value}</td>
                                            <td>{d.to.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                    </div>
                    <div className="col-sm-1"></div>
                </div><div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Research Experience</h2>
                        <div className="sep2"></div>

                        <table cellspacing="0" cellpadding="7" border="0">
                            <tbody>
                                <tr>
                                    <td >Research Interests  : </td>
                                    <td> {props.research.research.value}</td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Brief Research Statement  : </td>
                                    <td > {props.research.statement.value} </td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="col-sm-1"></div>
                </div>

                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Research Projects</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>

                                    <tr>
                                        <th>Role</th>
                                        <th>Project Type</th>
                                        <th>Title</th>
                                        <th>Funding Agency</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                      

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.respro.map((d, i) => {
                                        return (

                                            <tr key={i}>
                                                <td>{d.role.value}</td>
                                                <td>{d.projectType.value}</td>
                                                <td>{d.title.value}</td>
                                                <td>{d.funding.value}</td>
                                                <td>{d.from.value}</td>
                                                <td>{d.to.value}</td>
                                                <td>{d.amount.value}</td>
                                                <td>{d.status.value}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div><div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2> Journal Publications</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Journal Name</th>
                                        <th>Title &amp; Vol. No.</th>
                                        <th>Indexing (SCI/Web of science/Scopus)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.journal.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.year.value}</td>
                                                <td>{d.name.value}</td>
                                                <td>{d.title.value}</td>
                                                <td>{d.index.value}</td>
                                            </tr>

                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2> Conference Publications</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">

                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Conference</th>
                                        <th>Author(s)</th>
                                        <th>Title</th>
                                        <th>Indexing</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.conference.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.year.value}</td>
                                                <td>{d.conference.value}</td>
                                                <td>{d.author.value}</td>
                                                <td>{d.title.value}</td>
                                                <td>{d.index.value}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>

                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Book/Chapters Written</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Type (Book/Chapter)</th>
                                        <th>Title</th>
                                        <th>Publisher</th>
                                        <th>Author(s)</th>
                                        <th>ISBN/ISSN No.</th>
                                        <th>Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.books.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.type.value}</td>
                                                <td>{d.title.value}</td>
                                                <td>{d.publisher.value}</td>
                                                <td>{d.author.value}</td>
                                                <td>{d.isbn.value}</td>
                                                <td>{d.year.value}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10 mrgn">
                        <h2>Research Supersion</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Programme Name</th>
                                        <th>Scholar Name</th>
                                        <th>Research Topic</th>
                                        <th>Status</th>
                                        <th>Year</th>
                                        <th>Co-Superivisor(s)   </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.ressup.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.programme.value}

                                                </td>
                                                <td>{d.schName.value}</td>
                                                <td>{d.topic.value}</td>
                                                <td>{d.status.value}</td>
                                                <td>{d.year.value}</td>
                                                <td>{d.cosupervisor.value}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>





                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Patents</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table id="patentsTable" className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Name </th>
                                        <th>Reg./Ref.No.</th>
                                        <th>Date of Award/Filling</th>
                                        <th>Awarding Agencies</th>
                                        <th>Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.patents.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.name.value}</td>
                                                <td>{d.reg.value}</td>
                                                <td>{d.doa.value}</td>
                                                <td>{d.awardingagency.value}</td>
                                                <td>{d.status.value}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2> Conferences/Workshop/Courses Organized</h2>
                        <br />
                        <div className={classes.sep2}>

                            <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Type</th>
                                        <th>Title</th>
                                        <th>Venue</th>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Designation</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.confOrganized.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{d.category.value}</td>
                                                <td>{d.type.value}</td>
                                                <td>{d.title.value}</td>
                                                <td>{d.venue.value}</td>
                                                <td>{d.from.value}</td>
                                                <td>{d.to.value}</td>
                                                <td>{d.designation.value}</td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-sm-1"></div>
                </div>

                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Expert Talks</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Place</th>
                                    <th>Year</th>
                                    <th>Description of  Event</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.expertTalks.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.title.value}</td>
                                            <td>{d.place.value}</td>
                                            <td>{d.year.value}</td>
                                            <td>{d.doe.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Consultancy</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Title of Consultancy</th>
                                    <th>Client Organization</th>
                                    <th>Faculty Involved</th>
                                    <th>Amount(INR)</th>
                                    <th>Status</th>


                                </tr>
                            </thead>
                            <tbody>
                                {props.consultancy.map((d, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{d.title.value}</td>
                                            <td>{d.client.value}</td>
                                            <td>{d.faculty.value}</td>
                                            <td>{d.amount.value}</td>
                                            <td>{d.status.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                    </div>
                    <div className="col-sm-1"></div>
                </div><div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>
                            International and National Exposure
                </h2>
                        <br />
                        <div className="sep2"></div>
                        <table id="researchLabTable1" className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Title</th>
                                    <th>Description</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.exposure.map((d, i) => {
                                    return (
                                        <tr>
                                            <td>{d.srno.value}</td>
                                            <td>{d.title.value}</td>
                                            <td>{d.description.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>


                    </div><div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                        <h2>Honors &amp; Recognitions Achieved</h2>
                        <br />
                        <div className="sep2"></div>

                        <table className="table table-striped table-bordered dt-responsive nowrap" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Sr.No.</th>
                                    <th>Title</th>
                                    <th>Activity</th>
                                    <th>Given By</th>
                                    <th>Year</th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.honors.map((d, i) => {
                                    return (
                                        <tr>
                                            <td>{d.srno.value}</td>
                                            <td>{d.title.value}</td>
                                            <td>{d.activity.value}</td>
                                            <td>{d.given.value}</td>
                                            <td>{d.year.value}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-1"></div>
                </div>
                <div className={classes.row}>
                    <div className="col-sm-1"></div>
                    <div className="col-sm-1"></div>
                </div>
            </div>
        </div>
    )
}
export default Template3;