import { serverURL } from '../../config/config';
import axios from 'axios'
import Constant from '../actionTypes'


const login = (email, password, cb = null, ee = null) => {
    return async (dispatch) => {
        dispatch(request());
        try {
            let response = await axios.post(`${serverURL}/api/signin`, { email, password })
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('token', response.data.token)
            if (cb)
                cb();
            dispatch(success(response.data.user, response.data.token))
        } catch (err) {
            console.log(ee);
            
            if (ee)
                ee();
            dispatch(failure(err.response.data.error))
        }


    };

}
function request() {
    return { type: Constant.LOGIN_REQUEST }
}
function success(user, token = false, msg = null) {
    return { type: Constant.LOGIN_SUCCESS, user, token, msg }
}
function failure(msg) {

    return { type: Constant.LOGIN_FAILURE, msg }
}
function clearError() {
    return { type: Constant.AUTH_ERROR_CLEAR }
}


function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    return { type: Constant.LOGOUT };
}

function register(user,cb=null) {
    return async (dispatch) => {
        dispatch(request());
        try {
            await axios.post(`${serverURL}/api/signup`, user)
            if(cb)
                cb();
            dispatch(success(null, null,"Verify the email"))

        } catch (err) {
            cb();
            dispatch(failure(err.response.data.error))
        }

    };


}

// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         service.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => dispatch(failure(error.toString()))
//             );
//     };


// }

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         service.delete(id)
//             .then(
//                 user => dispatch(success(id)),
//                 error => dispatch(failure(id, error.toString()))
//             );
//     };
// }

export default {
    login,
    logout,
    register,
    clearError
};